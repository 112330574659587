import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/PropertyInvestment/Banner.js"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

const PropertyInvestment = () => {
  // Responsive rendering
  // const [width, setWidth] = React.useState(1199)

  // useEffect(() => {
  //   if (typeof window !== `undefined` && width ==undefined) {

  //     // const updateWidthAndHeight = () => {
  //       setWidth(1000)

  //   // }
  //   console.log("width", window.innerWidthth);
  // }
  // });

  return (
    <Layout>
      <SEO
        title="Property Investment | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      <PageBanner />

      {/* It Pays to do Your Homework */}
      <section id="homework">
        <div className="blue-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-5 pb-5">
                <h3 className="h3-careers">It Pays to do Your Homework</h3>
                <p>
                  It definitely pays to do your homework on the property market
                  before you dive in, and we’re thrilled to be on board to help
                  you when it comes to financing your decision. Recent share
                  market changes, tight rental markets in most capital cities
                  and a whiff of increase in property prices are seeing many mum
                  and dad investors retreat to bricks and mortar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* It's Still a Great Time to Invest */}
      <section id="invest">
        <div className="plain-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-5 pb-5">
                <h3 className="h3-careers">
                  It's Still a Great Time to Invest
                </h3>
                <p>
                  Property in Australia is still considered to be a sound
                  investment due to steady and consistent increases over time.
                  Putting aside the recent rises, it’s not a quick win. Property
                  usually has a seven to ten year cycle, with highs, lows and
                  steady stints in between.
                </p>
                <p>
                  Fortunately, an ongoing housing shortage in Australia and a
                  tax system that allows negative gearing on property (where any
                  investment losses can be claimed as tax deductions) continue
                  to favour housing as a solid, long-term investment.
                </p>
                <p>
                  But credit has tightened in the wake of the Global Financial
                  Crisis so lenders are more cautious about who borrows and for
                  what. We are here to help find the right lender and loan for
                  your circumstances in this new environment. We can also wade
                  through the many investment loan options on offer, leaving you
                  more time to find the ideal property.
                </p>
              </div>
            </div>

            <ReactWOW delay=".05s" animation="fadeInUp">
              <div className="row d-flex justify-content-center pb-5">
                <div className="col-12 col-md-5 col-lg-4">
                  <a
                    className="default-btn navy-btn-solid btn-lg mt-2 mt-md-0"
                    href="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/guides/Oxygen+Home+Loans+-+Investing+in+Property+guide.pdf"
                    target="_blank"
                  >
                    Download Guide Now
                  </a>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  {/* <a
                    className="default-btn navy-btn-solid light-teal-btn-outline-solid btn-lg mt-3 mt-md-0"
                    href={`${process.env.BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf022`}
                    target="_blank"
                  >
                    Apply Now
                  </a> */}
                  {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-teal-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-teal-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  )}
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>

      {/* FAQ Guide - Helpful Tips */}
      <section id="tips">
        <div className="blue-section-wrapper faq-dropdown-wrapper ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h3-careers">
                  Here are some tips to help you find the right rental and reap
                  the most rewards.
                </h3>
                <div className="faq-accordion mt-4 mt-md-5">
                  {/* {width > 1199 ? ( */}
                  <Accordion
                    preExpanded={["a", "b", "c", "d", "e", "f", "g", "h", "i"]}
                  >
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T1. Unit or house?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          House prices often increase in bigger strides than
                          units, offering more potential for capital gain over
                          time. But a rental home also comes with added
                          responsibilities, including gardens and lawns (and
                          sometimes a pool) to maintain.
                        </p>
                        <p className="mb-3">
                          A unit or townhouse may not increase in value as
                          quickly, but they are generally easier to maintain and
                          may even be easier to rent for that very reason,
                          depending on location, condition and size.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="b">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T2. Location, location
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          Of course, you’ve heard this before. But location can
                          mean different things when it comes to rental
                          properties. Renters are often looking for maximum
                          convenience so consider properties near schools, major
                          shopping centres and public transport.
                        </p>
                        <p className="mb-3">
                          Spend plenty of time researching target areas,
                          including recent property price movements and future
                          predictions, rental vacancy rates and any proposed
                          infrastructure improvements. You should also do some
                          scouting as if you were a renter to get a first-hand
                          look at the local market.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="c">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T3. Remove the emotion
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          One of the worst mistakes you can make with any
                          investment is to buy with your heart instead of your
                          head. Remember, your rental property is not your ‘home
                          sweet home’.
                        </p>
                        <p className="mb-3">
                          A well-presented property is desirable, but think
                          sensible, not swank. Ideally, you want a neutral
                          interior colour scheme, serviceable and resilient
                          flooring and window coverings, a low-maintenance yard
                          and good storage. And if buying an older style unit,
                          look for one with an internal laundry, a garage or car
                          space and few stairs (unless there’s a great view to
                          be had higher up, which can add to the property
                          value).
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="d">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T4. Don’t forget the extras
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          An investment property requires regular financial
                          commitment beyond the loan repayments. Make sure you
                          have the capacity to cover land and water rates and
                          any maintenance and repair costs. Tenants are entitled
                          to repairs or replacements as quickly as possible
                          under their rental agreement, so you will need to have
                          the means to pay.
                        </p>
                        <p className="mb-3">
                          Apartments or units also come with body corporate
                          fees, which can run to thousands in some modern
                          complexes with professional landscaping and shared
                          amenities, such as swimming pools.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="e">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T5. Cover your investment
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          Make sure you take out landlord’s insurance. This will
                          cover you for damage caused by a tenant and unpaid
                          rent if a tenant skips out, in addition to other
                          standard risks, such as a house fire or a storm.
                        </p>
                        <p className="mb-3">
                          If you invest in a strata title property, make sure
                          the body corporate has sufficient building insurance
                          to cover the cost of rebuilding the complex in today’s
                          prices. It’s often hard to work out what you need to
                          cover versus what the body corporate covers. A good
                          rule of thumb is everything from the wall paint inward
                          is yours and everything outside of that is covered by
                          the body corporate.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="f">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T6. Any interest?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          Many property investors take advantage of
                          interest-only loans because interest payments are tax
                          deductible. That means you’re taking a punt that the
                          property’s value will increase over time, leaving you
                          with a financial gain in the long run
                        </p>
                        <p className="mb-3">
                          This is a good strategy for high income earners who
                          are taking advantage of negative gearing. If you
                          choose to positive gear your investment (i.e. generate
                          a profit from the rental income after costs), you
                          might want to consider a principal and interest loan
                          and use the profit to shave off the principal. Just
                          remember, you will pay tax on any income from your
                          investment. Talk to your accountant about your tax
                          situation so your broker can find the right loan.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="g">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T7. Manage your investment
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          Managing a property takes time and energy. If you
                          don’t have much to spare of either, you should get a
                          professional property manager to advertise the rental,
                          screen and select tenants, collect and pay the rent,
                          co-ordinate repairs and maintenance, provide condition
                          reports and manage any disputes. Ask other local
                          landlords for referrals for reputable managers.
                        </p>
                        <p>
                          You should also conduct twice-yearly inspections
                          yourself. Any associated costs, including travel and
                          accommodation, are tax deductible.
                        </p>
                        <p>
                          If you decide to self-manage, you will need to be
                          well-versed on tenancy laws and prepared to organise
                          repairs, including those that arise after hours.
                        </p>
                        <p className="mb-3">
                          We understand every borrower has unique circumstances
                          – and that some are more complex than others. We know
                          from vast experience which lenders will work with
                          investment customers who have more complicated
                          requirements, and will negotiate on your behalf.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="h">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T8. Appreciate depreciation
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          The ATO will give you a discount off your tax bill for
                          wear and tear on property. It’s known as depreciation,
                          and can be a very handy windfall for investors,
                          especially if you buy a new property.
                        </p>
                        <p className="mb-3">
                          The formula is quite complex and depends on the age of
                          your property, building materials and the various
                          fittings. That’s where a professional quantity
                          surveyor comes in. For a fee (often around $600),
                          they’ll assess the property and complete a Tax
                          Depreciation Schedule, which your accountant will
                          incorporate in your tax return.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="i">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          T9. Taking ownership
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          If you need both incomes to be considered in the
                          lending equation, speak with us to get the right
                          advice on the best ownership equation for your
                          circumstances.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  {/* ) : (
                    <Accordion allowZeroExpanded>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T1. Unit or house?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            House prices often increase in bigger strides than
                            units, offering more potential for capital gain over
                            time. But a rental home also comes with added
                            responsibilities, including gardens and lawns (and
                            sometimes a pool) to maintain.
                          </p>
                          <p className="mb-3">
                            A unit or townhouse may not increase in value as
                            quickly, but they are generally easier to maintain
                            and may even be easier to rent for that very reason,
                            depending on location, condition and size.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T2. Location, location
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            Of course, you’ve heard this before. But location
                            can mean different things when it comes to rental
                            properties. Renters are often looking for maximum
                            convenience so consider properties near schools,
                            major shopping centres and public transport.
                          </p>
                          <p className="mb-3">
                            Spend plenty of time researching target areas,
                            including recent property price movements and future
                            predictions, rental vacancy rates and any proposed
                            infrastructure improvements. You should also do some
                            scouting as if you were a renter to get a first-hand
                            look at the local market.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T3. Remove the emotion
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            One of the worst mistakes you can make with any
                            investment is to buy with your heart instead of your
                            head. Remember, your rental property is not your
                            ‘home sweet home’.
                          </p>
                          <p className="mb-3">
                            A well-presented property is desirable, but think
                            sensible, not swank. Ideally, you want a neutral
                            interior colour scheme, serviceable and resilient
                            flooring and window coverings, a low-maintenance
                            yard and good storage. And if buying an older style
                            unit, look for one with an internal laundry, a
                            garage or car space and few stairs (unless there’s a
                            great view to be had higher up, which can add to the
                            property value).
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T4. Don’t forget the extras
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            An investment property requires regular financial
                            commitment beyond the loan repayments. Make sure you
                            have the capacity to cover land and water rates and
                            any maintenance and repair costs. Tenants are
                            entitled to repairs or replacements as quickly as
                            possible under their rental agreement, so you will
                            need to have the means to pay.
                          </p>
                          <p className="mb-3">
                            Apartments or units also come with body corporate
                            fees, which can run to thousands in some modern
                            complexes with professional landscaping and shared
                            amenities, such as swimming pools.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T5. Cover your investment
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            Make sure you take out landlord’s insurance. This
                            will cover you for damage caused by a tenant and
                            unpaid rent if a tenant skips out, in addition to
                            other standard risks, such as a house fire or a
                            storm.
                          </p>
                          <p className="mb-3">
                            If you invest in a strata title property, make sure
                            the body corporate has sufficient building insurance
                            to cover the cost of rebuilding the complex in
                            today’s prices. It’s often hard to work out what you
                            need to cover versus what the body corporate covers.
                            A good rule of thumb is everything from the wall
                            paint inward is yours and everything outside of that
                            is covered by the body corporate.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T6. Any interest?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            Many property investors take advantage of
                            interest-only loans because interest payments are
                            tax deductible. That means you’re taking a punt that
                            the property’s value will increase over time,
                            leaving you with a financial gain in the long run
                          </p>
                          <p className="mb-3">
                            This is a good strategy for high income earners who
                            are taking advantage of negative gearing. If you
                            choose to positive gear your investment (i.e.
                            generate a profit from the rental income after
                            costs), you might want to consider a principal and
                            interest loan and use the profit to shave off the
                            principal. Just remember, you will pay tax on any
                            income from your investment. Talk to your accountant
                            about your tax situation so your broker can find the
                            right loan.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T7. Manage your investment
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            Managing a property takes time and energy. If you
                            don’t have much to spare of either, you should get a
                            professional property manager to advertise the
                            rental, screen and select tenants, collect and pay
                            the rent, co-ordinate repairs and maintenance,
                            provide condition reports and manage any disputes.
                            Ask other local landlords for referrals for
                            reputable managers.
                          </p>
                          <p>
                            You should also conduct twice-yearly inspections
                            yourself. Any associated costs, including travel and
                            accommodation, are tax deductible.
                          </p>
                          <p>
                            If you decide to self-manage, you will need to be
                            well-versed on tenancy laws and prepared to organise
                            repairs, including those that arise after hours.
                          </p>
                          <p className="mb-3">
                            We understand every borrower has unique
                            circumstances – and that some are more complex than
                            others. We know from vast experience which lenders
                            will work with investment customers who have more
                            complicated requirements, and will negotiate on your
                            behalf.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T8. Appreciate depreciation
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3">
                            The ATO will give you a discount off your tax bill
                            for wear and tear on property. It’s known as
                            depreciation, and can be a very handy windfall for
                            investors, especially if you buy a new property.
                          </p>
                          <p className="mb-3">
                            The formula is quite complex and depends on the age
                            of your property, building materials and the various
                            fittings. That’s where a professional quantity
                            surveyor comes in. For a fee (often around $600),
                            they’ll assess the property and complete a Tax
                            Depreciation Schedule, which your accountant will
                            incorporate in your tax return.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            T9. Taking ownership
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            If you need both incomes to be considered in the
                            lending equation, speak with us to get the right
                            advice on the best ownership equation for your
                            circumstances.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Guide - Property Investing */}
      <section id="faq">
        <div className="plain-section-wrapper faq-dropdown-wrapper ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h3-careers">FAQ Guide - Property Investing</h3>
                <div className="faq-accordion mt-4 mt-md-5">
                  <Accordion preExpanded={["a", "b", "c", "d", "e", "f", "g"]}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q1. Why invest in property?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Australians are among the most active property
                          investors in the world, with an average of one in
                          every three new mortgages each month arranged for
                          investors. Most of these investors are ordinary people
                          with ordinary jobs earning ordinary incomes. So, why
                          is property investment so popular?
                        </p>
                        <h5 className="mt-3 mt-md-0">Capital growth</h5>
                        <p>
                          Capital growth is the increase in value of property
                          over time and the long term average growth rate for
                          Australian residential property is about 9% a year.
                          Importantly, because property markets move in cycles,
                          property values go through periods of stagnation as
                          well as decline. This is why taking an investment view
                          of at least 10 years is important. Note: if your
                          investment property increases by 7.5% a year, over a
                          10 year period it will double in value.
                        </p>
                        <h5 className="mt-3 mt-md-0">Rental income. </h5>
                        <p>
                          Rental income, also known as yield, is the rent an
                          investment property generates. You can calculate this
                          by dividing the annual rent by the price paid for the
                          property and multiplying it by 100 to produce a
                          percentage figure. As a general rule, more expensive
                          properties generate lower yields than more moderately
                          priced properties. There is also usually a direct,
                          inverse relationship between capital growth and rental
                          income. Those properties producing a lower rental
                          yield will often deliver greater capital growth over
                          the long term.
                        </p>
                        <h5 className="mt-3 mt-md-0">Tax benefits.</h5>
                        <p>
                          The Federal Government allows you to offset against
                          your taxable income any losses you incur from owning
                          an investment property. For example, if the amount you
                          receive in rent from tenants is $5,000 less than the
                          cost of servicing the mortgage, and paying rates,
                          water and other fees associated with the property, at
                          the end of the year you can add that $5,000 to the
                          amount of income on which you don’t have to pay tax.
                          If you work as an employee, with income tax
                          automatically deducted from your pay, this means
                          you’ll receive a refund from the Australian Taxation
                          Office (ATO) after the end of the financial year.
                        </p>
                        <h5 className="mt-3 mt-md-0">Low volatility.</h5>
                        <p>
                          Property values generally fluctuate less than the
                          stock market. Many investors say they experience
                          greater peace of mind for this reason.
                        </p>
                        <h5 className="mt-3 mt-md-0">Leverage.</h5>
                        <p>
                          Property enables far greater leverage than many other
                          investments. For example, if you have $100,000 in
                          savings, you could invest it in a portfolio of shares,
                          or use it to buy a property worth $500,000 by taking
                          out a mortgage for $400,000. If shares go up by 10%
                          during the year, your share portfolio would be worth
                          $110,000 and you would have gained $10,000. If
                          property goes up by 10% during that same year, your
                          property would be worth $550,000 and you would have
                          gained $50,000.
                        </p>
                        <h5 className="mt-3 mt-md-0">
                          You don’t need a big salary to invest.
                        </h5>
                        <p className="mb-3">
                          If you are buying to invest, lenders will take rental
                          income as well as your own income into their
                          assessment. If you already own your own home and have
                          some equity in it, you may be able to use this as a
                          deposit, meaning that you can buy an investment
                          property without having to find any additional cash.
                          If you don’t own your own home and feel you may never
                          be able to afford one, buying an investment property
                          may be a good stepping stone to one day being able to
                          afford your own home.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="b">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q2. How much money can I borrow?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          We’re all unique when it comes to our finances and
                          borrowing needs. Get an estimate on how much you could
                          borrow with our Home Loan Quote in 30 seconds. Or
                          contact us today, we can help with calculations based
                          on your circumstances.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="c">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q3. How do I choose a loan that’s right for me?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Our guides to loan types and features will help you
                          learn about the main options available. There are
                          hundreds of different home loans available, so talk to
                          us today.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="d">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q4. How much do I need for a deposit?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Usually between 5% – 10% of the value of a property,
                          which you pay when signing a Contract of Sale. Speak
                          with us to discuss your options for a deposit. You may
                          be able to borrow against the equity in your existing
                          home or an investment property.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="e">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q5. How much will regular repayments be?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Go to our{" "}
                          <a
                            href="https://oxygenhomeloans.smartonline.com.au/calculators/loan-repayment-calculator/"
                            target="_blank"
                            class="underlined-link link"
                          >
                            Repayment Calculator
                          </a>{" "}
                          for an estimate. Because there so many different loan
                          products, some with lower introductory rates, talk to
                          us today about the deals currently available, we’ll
                          work with you to find a loan set-up that’s right for
                          you.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="f">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q6. How often do I make home loan repayments — weekly,
                          fortnightly or monthly?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Most lenders offer flexible repayment options to suit
                          your pay cycle. Aim for weekly or fortnightly
                          repayments, instead of monthly, as you will make more
                          payments in a year, which will shave dollars and time
                          off your loan.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="g">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q7. What fees/costs should I budget for?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3">
                          There are a number of fees and costs involved when
                          buying a property. To help avoid any surprises, the
                          list below sets out many of the usual costs:
                        </p>
                        <ul className="mb-3">
                          <li>
                            <span className="main-point">Stamp duty</span> —
                            This is the big one. All other costs are relatively
                            small by comparison. Stamp duty rates vary between
                            state and territory governments and also depend on
                            the value of the property you buy. You may also have
                            to pay stamp duty on the mortgage itself. To
                            estimate your possible stamp duty charge, visit our{" "}
                            <Link
                              to="/calculators/stamp-duty-calculator"
                              className="underlined-link link"
                            >
                              Stamp Duty Calculator.
                            </Link>
                          </li>
                          <li>
                            <span className="main-point">
                              Legal/conveyancing fees
                            </span>{" "}
                            — Generally around $1,000 – $1500, these fees cover
                            all the legal requirements around your property
                            purchase, including title searches.
                          </li>
                          <li>
                            <span className="main-point">
                              Building inspection
                            </span>{" "}
                            — This should be carried out by a qualified expert,
                            such as a structural engineer, before you purchase
                            the property. Your Contract of Sale should be
                            subject to the building inspection, so if there are
                            any structural problems you have the option to
                            withdraw from the purchase without any significant
                            financial penalties. A building inspection and
                            report can cost up to $1,000, depending on the size
                            of the property. Your conveyancer will usually
                            arrange this inspection, and you will usually pay
                            for it as part of their total invoice at settlement
                            (in addition to the conveyancing fees).
                          </li>
                          <li>
                            <span className="main-point">Pest inspection</span>{" "}
                            — Also to be carried out before purchase to ensure
                            the property is free of problems, such as white
                            ants. Your Contract of Sale should be subject to the
                            pest inspection, so if any unwanted crawlies are
                            found you may have the option to withdraw from the
                            purchase without any significant financial
                            penalties. Allow up to $500 depending on the size of
                            the property. Your real estate agent or conveyancer
                            may arrange this inspection, and you will usually
                            pay for it as part of their total invoice at
                            settlement (in addition to the conveyancing fees).
                          </li>
                          <li>
                            <span className="main-point">Lender costs</span> —
                            Most lenders charge establishment fees to help cover
                            the costs of their own valuation as well as
                            administration fees. We will let you know what your
                            lender charges but allow about $600 to $800.
                          </li>
                          <li>
                            <span className="main-point">Moving costs</span> —
                            Don’t forget to factor in the cost of a removalist
                            if you plan on using one.
                          </li>
                          <li>
                            <span className="main-point">
                              Mortgage Insurance costs
                            </span>{" "}
                            — If you borrow more than 80% of the purchase price
                            of the property, you’ll also need to pay Lender
                            Mortgage Insurance. You may also consider whether to
                            take out Mortgage Protection Insurance. If you buy a
                            strata title, regular strata fees are payable.
                          </li>
                          <li>
                            <span className="main-point">Ongoing costs</span> —
                            You will need to include council and water rates
                            along with regular loan repayments. It is important
                            to also take out building insurance and contents
                            insurance. Your lender will probably require a
                            minimum sum insured for the building to cover the
                            loan, but make sure you actually take out enough
                            building insurance to cover what it would cost if
                            you had to rebuild. Likewise, make sure you have
                            enough contents cover should you need to replace
                            everything if the worst happens.
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default PropertyInvestment
